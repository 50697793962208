import { Link, navigate } from "gatsby"
import React from "react"
import { useStaticQuery, graphql } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import './style.scss'
import dataLayerEngagement from "../DataLayerEngagement"
import LensesCta from "../LensesCta"
import WebpImage from "../WebpImage"
import Banner from "../Banner";


const HubbleHeroPromo = () => {
  const handleClick = (e) => {
    e.preventDefault()
    dataLayerEngagement("home_page_engagement", "1","Hubble Contacts", `${e.target.text}`)
    navigate(e.target.href)
  }

  return (
    <div className="hubble-hero-holiday">
      <div className='banner-homepage-v2'>
        <Banner
          bannerText={{
            mobile: <>Start your subscription now for up to 80% Off.<br/><span class='cta-button-banner'>Shop Now</span></>,
            desktop: <>Start your subscription now for up to 80% Off. <span class='cta-button-banner'>Shop Now</span></>,
          }}
          bannerBG={"purple"}
          bannerClass='homepage-v2-banner discount-banner'
          buttonLink={"/contact-lenses/hubble"}
          clickable
        />
      </div>
      <div className="hubble-hero-holiday--background">
        <WebpImage objectFit="contain" wrapperClassName="pc-image" fileName="Pages/Homepage/Hubble-Contacts-Homepage-Hero-Desktop-BFCM.png" alt="Homepage banner featuring highlighted products." />
        <WebpImage objectFit="contain" wrapperClassName="mobile-image" fileName="Pages/Homepage/Memorial-Day-Sale-HP.jpeg" alt="Homepage banner featuring highlighted products." />
      </div>
      <div className="grid-section">
        <p className="limited-time">limited time only</p>
        <h1 className="gradient-title">Summer Sale</h1>
        <p className="hero-text">
          <p className="mobile-only">
            Get your first order of Hydro by Hubble for just <b>$4.99/eye, </b>or SkyHy by Hubble for <b>$9.99/eye.</b>
          </p>
          <p className="desktop-only">
            Get your first order of Hydro by Hubble for <b>$4.99/eye, </b>
            or SkyHy by Hubble for <b>$9.99/eye.</b>
          </p>
        </p>
        <a href="/contact-lenses/hydro-by-hubble-lenses" onClick={handleClick} className="btn btn-primary cta-btn">
          Shop Hydro by Hubble
        </a>
        <a href="/contact-lenses/skyhy-by-hubble-lenses" onClick={handleClick} className="btn btn-primary cta-btn">
          Shop SkyHy by Hubble
        </a>
      </div>
    </div>
  )
}

const HubbleLensesCta = ({product}) => {
  return (
    <div className="hubble-hydro-cta">
      <LensesCta product={product} heroBanner={true} />
    </div>
  )
}

export {HubbleHeroPromo as default, HubbleLensesCta}
