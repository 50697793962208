import { usePromo as getPromo, promoStart, promoEnd } from "../../utils/usePromo";
import {useWindowWidth as getWindowWidth} from "../../services/hooks";
import getIsLifemart from "../Lifemart/hooks/useIsLifemart";
import getIsMilitary from "../Military/hooks/useIsMilitary";
import {getHubblePricing} from "../../services/data";

export const comparisonInfo = () => {
  const isPromo = getPromo(promoStart, promoEnd)
  return(
    {
      price: {
        classic: isPromo ?
          "<p><strike>$19.99/eye</strike> <b>$1*</b></p><p class='limited-time-text mb-0 invisible'>Limited <br class='d-block d-sm-none'/>Time Only</p>" :
          "<p><strike>$19.99/eye</strike> <b>$1*</b></p>",
        hydro: isPromo ?
          "<p><strike>$24.99/eye</strike> <b>$9.99/eye*</b></p> <p class='limited-time-text mb-0'>Limited <br class='d-block d-sm-none'/>Time Only</p>" :
          "<p><strike>$24.99/eye</strike> <b>$9.99/eye*</b></p>",
        skyhy: isPromo ?
          "<p><strike>$29.99/eye</strike> <b>$11.99/eye*</b></p><p class='limited-time-text mb-0'>Limited <br class='d-block d-sm-none'/>Time Only</p>" :
          "<p><strike>$29.99/eye</strike> <b>$17.99/eye*</b></p>",
      },
      fda: {
        classic: true,
        hydro: true,
        skyhy: true,
      },
      personalized: {
        classic: true,
        hydro: true,
        skyhy: true,
      },
      eco: {
        classic: true,
        hydro: true,
        skyhy: true,
      },
      daily: {
        classic: true,
        hydro: true,
        skyhy: true,
      },
      natural: {
        classic: "",
        hydro: true,
        skyhy: true,
      },
      blink: {
        classic: "",
        hydro: true,
        skyhy: true,
      },
      world: {
        classic: "",
        hydro: true,
        skyhy: "",
      },
      touch: {
        classic: "",
        hydro: true,
        skyhy: "",
      },
      high_oxygen: {
        classic: "",
        hydro: "",
        skyhy: true,
      },
      flex_wear: {
        classic: "",
        hydro: true,
        skyhy: true,
      },
      top_choise: {
        classic: "",
        hydro: "",
        skyhy: true,
      },

    }
  )
}

export const labels = {
  price: "first order price",
  fda: "Fda-approved",
  personalized: "personalized delivery cadence",
  daily: {text: "daily disposables", sub: 'The safest type of contact lenses'},
  eco: {text: "Packaging made from recyclable material"},
  natural: {text: "Natural Eye Hydration", sub: "Material and shape are specially designed to retain moisture on the lens surface"},
  blink: {text: "Blink Comfort Design", sub: "Lens design minimizes discomfort when blinking"},
  world: {text: "World's slimmest lens packaging", sub: "Less plastic, less space, more convenient"},
  touch: {text: "Hygienic Touch Technology", sub: "Packaged so that your fingers never touch the inside of the lens"},
  high_oxygen: {text: "High Oxygen PERMEABILITY", sub: "Cutting-edge silicone hydrogel material allows oxygen to move through the lens for fresh eyes all day"},
  flex_wear: {text: "Soft flex wear", sub: "Flexible construction allows lenses to mold to the curve of your eye, fitting snugly and comfortably over the surface"},
  top_choise: {text: "Doctors’ top choice", sub: "7 out of 10 new prescriptions are for lenses made of SkyHy's material (silicone hydrogel)"},
}

export const contactLenses = () => {
  const isPromo = getPromo(promoStart, promoEnd)
  const isLifemart = getIsLifemart()
  const isMilitary = getIsMilitary()

  return(
    [
      !isLifemart && !isMilitary
      ? {title: "Classic by Hubble", feature: "affordable dailies", type: "classic", product: "Classic", price: "$1 Starter Pack", originalPrice: "$22.99/eye"}
      : {title: "Classic by Hubble", feature: "affordable dailies", type: "classic", product: "Classic", price: "$1 Starter Pack", originalPrice: "$19.99/eye"},
      {title: "Hydro by Hubble", feature: "naturally hydrating", type: "hydro", product: "Hydro", price: isPromo ? "$9.99/eye" :  "$9.99/eye", originalPrice: "$24.99/eye", percent_off:"80", tagText:'popular choice', tagColor: 'purple'},
      {title: "SkyHy by Hubble", feature: "most breathable", type: "skyhy", product: "Skyhy", price: isPromo ? "$11.99/eye" : "$17.99/eye", originalPrice: "$29.99/eye", percent_off:"66", tagText:'premium pick', tagColor: 'orange'},
    ]
  )
}

export const contactLensesV2 = () => {
  const windowWidth = getWindowWidth()
  const isMobile = windowWidth <= 1024
  const isLifemart = getIsLifemart()
  const isMilitary = getIsMilitary()
  const hubblePricing = getHubblePricing()
  let hydroFirstPrice = `$${hubblePricing.hydro.firstPrice}/eye`
  let skyhyFirstPrice = `$${hubblePricing.skyhy.firstPrice}/eye`
  let skyhyMonthlyFirstPrice = `$${hubblePricing.skyhyMonthly.firstPricePerMonth}/eye`
  let hydroSubPrice = `$${hubblePricing.hydro.subPrice}/eye`
  let skyhySubPrice = `$${hubblePricing.skyhy.subPrice}/eye`
  let skyhyMonthlySubPrice = `$${hubblePricing.skyhyMonthly.subPricePerMonth}/eye`

  return(
    isMobile ?
    [
      {title: "SkyHy by Hubble<sup>®</sup> Monthly", feature: "month-long comfort", type: "skyhyMonthly", product: "SkyHy-Monthly", price: skyhyMonthlyFirstPrice, originalPrice: skyhyMonthlySubPrice, tagText: 'New!', tagColor: 'charged-blue', packSize: 6, circleTagText: "Best Value", circleTagColor: "blue", percent_off: hubblePricing.skyhyMonthly.percentOff, cadence: 168, monthPriceDisclaimer: true},
      {title: "SkyHy by Hubble<sup>®</sup> Daily", feature: "most breathable", type: "skyhy", product: "Skyhy", price: skyhyFirstPrice, originalPrice: skyhySubPrice, tagText:'premium pick', tagColor: 'orange', packSize: 30, percent_off: hubblePricing.skyhy.percentOff, cadence: 28},
      {title: "Hydro by Hubble<sup>®</sup> Daily", feature: "naturally hydrating", type: "hydro", product: "Hydro", price: hydroFirstPrice, originalPrice: hydroSubPrice, tagText:'popular choice', tagColor: 'purple', packSize: 30, percent_off: hubblePricing.hydro.percentOff, cadence: 28},
      !isLifemart && !isMilitary
      ? {title: "Hubble<sup>®</sup> Classic Daily", feature: "affordable dailies", type: "classic", product: "Classic", price: "<b>$1.00</b> <br/><span class='weak'>10-day starter pack</span>", originalPrice: "$22.99/eye", packSize: 30, cadence: 28}
      : {title: "Hubble<sup>®</sup> Classic Daily", feature: "affordable dailies", type: "classic", product: "Classic", price: "<b>$1.00</b> <br/><span class='weak'>10-day starter pack</span>", originalPrice: "$19.99/eye", packSize: 30, cadence: 28},
    ]
    :
    [
      !isLifemart && !isMilitary
      ? {title: "Hubble<sup>®</sup> Classic Daily", feature: "affordable dailies", type: "classic", product: "Classic", price: "<b>$1.00</b> <br/><span class='weak'>10-day starter pack</span>", originalPrice: "$22.99/eye", packSize: 30, cadence: 28}
      : {title: "Hubble<sup>®</sup> Classic Daily", feature: "affordable dailies", type: "classic", product: "Classic", price: "<b>$1.00</b> <br/><span class='weak'>10-day starter pack</span>", originalPrice: "$19.99/eye", packSize: 30, cadence: 28},
      {title: "Hydro by Hubble<sup>®</sup> Daily", feature: "naturally hydrating", type: "hydro", product: "Hydro", price: hydroFirstPrice, originalPrice: hydroSubPrice, tagText:'popular choice', tagColor: 'purple', packSize: 30, percent_off: hubblePricing.hydro.percentOff, cadence: 28},
      {title: "SkyHy by Hubble<sup>®</sup> Daily", feature: "most breathable", type: "skyhy", product: "Skyhy", price: skyhyFirstPrice, originalPrice: skyhySubPrice, tagText:'premium pick', tagColor: 'orange', packSize: 30, percent_off: hubblePricing.skyhy.percentOff, cadence: 28},
      {title: "SkyHy by Hubble<sup>®</sup> Monthly", feature: "month-long comfort", type: "skyhyMonthly", product: "SkyHy-Monthly", price: skyhyMonthlyFirstPrice, originalPrice: skyhyMonthlySubPrice, tagText:'New!', tagColor: 'charged-blue', packSize: 6, circleTagText: "Best Value", circleTagColor: "blue", percent_off: hubblePricing.skyhyMonthly.percentOff, cadence: 168, monthPriceDisclaimer: true},
    ]
  )
}
