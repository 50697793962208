import { Link, navigate } from "gatsby"
import React, { useState, useEffect } from "react"
import dataLayerEngagement from "../DataLayerEngagement"
import { useWindowWidth } from '../../services/hooks'
import {hubbleClassic} from "../../services/data";
import WebpImage from "../WebpImage"

import "./style.scss"

const ValuePropsSection = ({isHomePageV2}) => {
  const [isMobile, setIsMobile] = useState()
  const windowWidth = useWindowWidth()

  useEffect(() => {
    setIsMobile(windowWidth < 767)
}, [windowWidth])

  const handleClick = (e) => {
    e.preventDefault()
    dataLayerEngagement("home_page_engagement", "6","In sight. Out of Mind.", `Start for $1`)
    navigate(e.target.href)
  }

  return (
    <div className={`value-props grid-section ${isHomePageV2 ? 'no-padding' : ''}`}>
      {!isHomePageV2 &&
        <>
        <div className="image-container">
        <div className="background-desktop desktop">
          <WebpImage
            fileName="Pages/Homepage/Hubble-Contacts-Homepage-Inset-Image-Desktop.webp"
            alt="Colorful boxes of Hubble prescription contacts available to order online"
          />
        </div>
        <div className="background-desktop ipad">
          <WebpImage
            fileName="Pages/Homepage/Hubble-Contacts-Homepage-Inset-Image-iPad.webp"
            alt="Colorful boxes of Hubble prescription contacts available to order online"
          />
        </div>
        <div className="background-desktop mobile">
          <WebpImage
            fileName="Pages/Homepage/Hubble-Contacts-Homepage-Inset-Image-mobile.webp"
            alt="Colorful boxes of Hubble prescription contacts available to order online"
          />
        </div>

        <div className="copy-holder desktop">
          <h2>In Sight. Out of Mind.</h2>
          <p className="subheader">
          Subscribe and save with a first-order discount on all of our daily prescription contact lenses.<br/> Hubble makes it easy to buy contacts online.
          </p>
          <Link onClick={handleClick} to="/contact-lenses"className="btn btn-primary cta-btn">
          Shop Contact Lenses
          </Link>
        </div>
      </div>
      <div className="copy-holder mobile">
        <h2>In Sight. Out of Mind.</h2>
        <p className="subheader">Subscribe and save with a first-order discount on all of our daily prescription contact lenses. Hubble makes it easy to buy contacts online.</p>
        <Link onClick={handleClick} to="/contact-lenses"className="btn btn-primary cta-btn">
          Shop Contact Lenses
        </Link>
      </div>
      </>
      }

      {/* <LensesCta product="skyhy" /> */}
      <div className="value-props-holder">
        <div className="value-prop">
        <WebpImage
            fileName="Pages/MilitaryPage/MilitaryValuePropsSection/01-Hubble-Contacts-Start-for-$1.png"
            alt=""
          />
          <p className="value-title">Start for $1</p>
          <p className="value-copy">
            Pay $1 for your first {hubbleClassic.trialPeriodPairs} pairs of our classic lenses – or start SkyHy or Hydro by Hubble for a special price.
          </p>
        </div>

        <div className="value-prop">
        <WebpImage
            fileName="Pages/MilitaryPage/MilitaryValuePropsSection/02-Hubble-Contacts-No-Hassle-Policies.png"
            alt=""
          />
          <p className="value-title">{isMobile && isHomePageV2 ? 'Free, ' : ''}No-Hassle {isHomePageV2 ? "Policies" : "Returns"}</p>
          <p className="value-copy">Not seeing the value? Just send it back.</p>
        </div>

        <div className="value-prop">
        <WebpImage
            fileName="Pages/MilitaryPage/MilitaryValuePropsSection/03-Hubble-Contacts-Cancel-Any-Time.png"
            alt="Cancel your subscription at any time"
          />
          <p className="value-title">Cancel at any Time</p>
          <p className="value-copy">
            Cancel your contact lens subscription at any time, for any reason.
          </p>
        </div>

        <div className="value-prop">
        <WebpImage
            fileName="Pages/MilitaryPage/MilitaryValuePropsSection/04-Hubble-Contacts-Eye-Health.png"
            alt=""
          />
          <p className="value-title">Eye Health & Safety {!!(isMobile && isHomePageV2) && <p className="value-title">Peace-of-Mind</p>}</p>
          <p className="value-copy">
            All Hubble lenses are FDA-approved, thoroughly inspected, and made with care.
          </p>
        </div>

        <div className="value-prop">
        <WebpImage
            fileName="Pages/MilitaryPage/MilitaryValuePropsSection/05-Hubble-Contacts-Secure-Payment.png"
            alt=""
          />
          <p className="value-title">Secure Payment</p>
          <p className="value-copy">
            Safety first across all payment platforms.
          </p>
        </div>
      </div>
    </div>
  )
}

export default ValuePropsSection
